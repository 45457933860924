import React, { FC } from 'react';
import { Route } from 'react-router-dom';

import * as O from 'fp-ts/Option';

import { safeLazy } from '@core/router';
import Layout from '@layout/Layout';
import { useAuthContext } from '@modules/auth/context';
import { RestrictedRoute, Routes } from '@core/router/Routes';
import ErrorPage from '@shared/components/error-page/ErrorPage';
import { HttpError } from '@core/http';
import { AdminUserRole } from '@modules/users/model';
import SentryProvider from '@shared/modules/sentry/components/SentryProvider';

// Auth Pages
const LoginPage = safeLazy(() => import('@modules/auth/LoginPage'));
const ForgotPasswordPage = safeLazy(() => import('@modules/auth/ForgotPasswordPage'));
const ResetPasswordPage = safeLazy(() => import('@modules/auth/ResetPasswordPage'));
const ActivationPage = safeLazy(() => import('@modules/auth/ActivationPage'));

const CustomersRoutes = safeLazy(() => import('@modules/customers/routes'));
const CustomerAgenciesRoutes = safeLazy(() => import('@modules/customer-agencies/routes'));
const HousingSitesRoutes = safeLazy(() => import('@modules/housing-sites/routes'));
const HousingsRoutes = safeLazy(() => import('@modules/housings/routes'));
const DoorsRoutes = safeLazy(() => import('@modules/doors/routes'));
const DoorOpeningLogsRoutes = safeLazy(() => import('@modules/door-opening-logs/routes'));
const AdminRoutes = safeLazy(() => import('@modules/users/routes'));
const ProfileRoutes = safeLazy(() => import('@modules/profile/routes'));
const BuildingsRoutes = safeLazy(() => import('@modules/buildings/routes'));
const OccupantsRoutes = safeLazy(() => import('@modules/occupants/routes'));
const AccessoryRequestsRoutes = safeLazy(() => import('@modules/accessory-requests/routes'));
const AccessoriesRoutes = safeLazy(() => import('@modules/accessories/routes'));
const CustomerUsersRoutes = safeLazy(() => import('@modules/customer-users/routes'));
const PassesRoutes = safeLazy(() => import('@modules/passes/routes'));
const AlertsRoutes = safeLazy(() => import('@modules/alerts/routes'));
const SupervisionRoutes = safeLazy(() => import('@modules/supervision/routes'));
const PartnersRoutes = safeLazy(() => import('@modules/partners/routes'));
const DashboardPage = safeLazy(() => import('@modules/dashboard/DashboardPage'));

const RootRoutes: FC = () => {
  const { profile } = useAuthContext();

  return (
    <SentryProvider profile={profile}>
      <Routes>
        <Route element={<Layout profile={O.toNullable(profile)} />}>
          <Route path="/profile/*" element={<ProfileRoutes profile={O.toNullable(profile)} />} />

          <Route
            path="/"
            element={
              <RestrictedRoute roles={[AdminUserRole.Admin, AdminUserRole.HotLiner]} redirect="/customer-agencies">
                {profile => <DashboardPage profile={profile} />}
              </RestrictedRoute>
            }
          />
          <Route path="/customers/*" element={<CustomersRoutes />} />
          <Route path="/customer-agencies/*" element={<CustomerAgenciesRoutes />} />
          <Route path="/housing-sites/*" element={<HousingSitesRoutes />} />
          <Route path="/housing-buildings/*" element={<BuildingsRoutes />} />
          <Route path="/housings/*" element={<HousingsRoutes />} />
          <Route path="/doors/*" element={<DoorsRoutes />} />
          <Route path="/door-opening-logs/*" element={<DoorOpeningLogsRoutes />} />
          <Route path="/occupants/*" element={<OccupantsRoutes />} />
          <Route path="/accessory-requests/*" element={<AccessoryRequestsRoutes />} />
          <Route path="/alerts/*" element={<AlertsRoutes />} />
          <Route path="/accessories/*" element={<AccessoriesRoutes />} />
          <Route path="/customer-users/*" element={<CustomerUsersRoutes />} />
          <Route path="/passes/*" element={<PassesRoutes />} />

          <Route path="/users/*" element={<AdminRoutes />} />
          <Route path="/supervision/*" element={<SupervisionRoutes />} />
          <Route path="/partners/*" element={<PartnersRoutes />} />

          <Route path="*" element={<ErrorPage error={HttpError.notFound} />} />
        </Route>

        <Route>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/password-reset/forgot" element={<ForgotPasswordPage />} />
          <Route path="/password-reset/:token" element={<ResetPasswordPage />} />
          <Route path="/activation/:token" element={<ActivationPage />} />
        </Route>
      </Routes>
    </SentryProvider>
  );
};

export default RootRoutes;
